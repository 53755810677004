import React, { useState, useEffect } from "react";
import WithdrawalHistory from "../components/withdrawalHistory";
import WebApp from "@twa-dev/sdk";
import { MainButton } from "@twa-dev/sdk/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { getAvailableAmountToInvest, withdrawFunds, getPendingAmount } from "../helpers/api";
import {
  Form,
  Input,
  Typography,
  message as antMessage,
  Spin,
  InputNumber,
  Checkbox,
  Alert
} from "antd";
import { isValidTonAddress } from "../helpers/functions"
import type { CheckboxProps } from "antd";

const { Title, Text } = Typography;

const WithdrawalPage: React.FC = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state: RootState) => state.user);
  const tonPrice = useSelector((state: RootState) => state.ton.tonPrice);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [amount, setAmount] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tonAmount, setTonAmount] = useState<number | null>(null);
  const [shouldRefreshHistory, setShouldRefreshHistory] =
    useState<boolean>(false);
  const [usePartnerBalance, setUsePartnerBalance] = useState<boolean>(false);
  const [availableAmount, setAvailableAmount] = useState<number | null>(null);
  const [pendingAmount, setPendingAmount] = useState<number>(0);
  const [banner, setBanner] = useState<boolean>(false);

  useEffect(() => {
    WebApp.BackButton.show();
    WebApp.BackButton.onClick(() => window.history.back());

    return () => {
      WebApp.BackButton.hide();
    };
  }, []);

  useEffect(() => {
    if (amount && tonPrice) {
      setTonAmount(amount / tonPrice);
    } else {
      setTonAmount(null);
    }
  }, [amount, tonPrice]);

  const fetchAvailableAmount = async () => {
    try {
      const data = await getAvailableAmountToInvest();
      if (data.error) {
        antMessage.error(t(data.error));
      } else {
        setAvailableAmount(data.available_withdrawal_amount);
        setBanner(data.banner)
      }
      const response = await getPendingAmount();
      if (response.error) {
        antMessage.error(t(response.error));
      } else {
        setPendingAmount(response.total_loan_amount);
      }
    } catch (error) {
      antMessage.error(t("Error fetching available withdrawal amount"));
      console.error("Error fetching available withdrawal amount", error);
    }
  };

  useEffect(() => {
    fetchAvailableAmount();
  }, [userData]);

  const handleSubmit = () => {

    // Validate wallet address
    if (!walletAddress.trim()) {
      antMessage.error(t("Please enter your wallet address"));
      return;
    }

    // Validate amount
    if (amount === null || amount <= 0) {
      antMessage.error(t("Please enter a valid withdrawal amount"));
      return;
    }

    if (!isValidTonAddress(walletAddress)) {
      antMessage.error(t("Incorrect wallet address"));
      return;
  }

    const withdrawalData = {
      walletAddress,
      amount,
      usePartnerBalance,
    };

    setLoading(true);
    withdrawFunds(withdrawalData)
      .then((response) => {
        setLoading(false);
        if (response.error) {
          antMessage.error(t(response.error));
        } else {
          antMessage.success(t("Withdrawal request submitted successfully"));
          setWalletAddress("");
          setAmount(null);
          setShouldRefreshHistory((prev) => !prev);
          fetchAvailableAmount();
        }
      })
      .catch((error) => {
        setLoading(false);
        antMessage.error(t("Error submitting withdrawal request"));
        console.error("Error submitting withdrawal request", error);
      });
  };

  const onCheckboxChange: CheckboxProps["onChange"] = (e) => {
    setUsePartnerBalance(e.target.checked);
  };

  if (availableAmount === null) {
    return (
      <div className="loader-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
      }}
    >
      {banner && <Alert message={t("Withdraw_banner")} type="error" showIcon />}
      <Title level={4}>{t("Withdraw funds")}</Title>(Beta)
      <div className="balance_item">
        <span>{t("balance")}</span>
        <span className="balance_item_price">
        <Text strong>${userData?.real_balance}</Text>
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Partner Balance")}</span>
        <span className="balance_item_price">
        <Text strong>${userData?.partner_balance}</Text>
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Frozen")}</span>
        <span className="balance_item_price">
        <Text strong>${(userData!.partner_balance - availableAmount).toFixed(2)}</Text>
        </span>
      </div>
      <div className="balance_item">
        <span>{t("loan_status.pending")}</span>
        <span className="balance_item_price">
        <Text strong>${pendingAmount.toFixed(2)}</Text>
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Available amount for withdrawal")}</span>
        <span className="balance_item_price">
        <Text strong>${usePartnerBalance ? availableAmount.toFixed(2) : userData?.real_balance}</Text>
        </span>
      </div>


      <Form layout="vertical" style={{marginTop: 20}}>
        <Form.Item>
          <Checkbox onChange={onCheckboxChange}>
            {t("Withdrawal from the partner balance")}
          </Checkbox>
        </Form.Item>
        <Form.Item label={t("Wallet Address") + " (TON)"} required>
          <Input
            placeholder={t("Enter your wallet address")}
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
            disabled={banner}
          />
        </Form.Item>

        <Form.Item label={t("Amount")} extra="Min. $10" required>
          <InputNumber
            placeholder={t("Enter amount to withdraw")}
            value={amount}
            onChange={(value) => setAmount(value)}
            style={{ width: "100%" }}
            min={10}
            max={usePartnerBalance ? availableAmount : userData?.real_balance}
            disabled={banner}
          />
          {tonAmount && (
            <Text type="secondary">
              {t("You will receive")} {tonAmount.toFixed(6)} TON
            </Text>
          )}
        </Form.Item>
      </Form>
      <WithdrawalHistory shouldRefresh={shouldRefreshHistory} />
      <MainButton
        text={t("Withdraw")}
        onClick={handleSubmit}
        disabled={loading}
      />
      {loading && (
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default WithdrawalPage;
